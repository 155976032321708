<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">{{ $t("message.online_work") }}</div>
      <div>
        <el-button class="my-btn " type="warning" @click="take" round>
         {{ $t("message.online_work") }}
        </el-button>
        <router-link :to="{ name: 'onlineWork' }">
          <div class="my-btn cr1 el-button ml20" >
            <i class="el-icon-d-arrow-left"></i> {{ $t("message.back") }}
          </div>
        </router-link>
      
      </div>
    </div>
    <div class="content-block">
      <div class="comment bg p20 mb20">
        <el-divider content-position="left"> {{ $t("message.reason") }} </el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input type="textarea" v-model="form.reason"></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="datetime-start bg p20 mb20">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-divider content-position="left">{{ $t("message.day") }}</el-divider>
            <div class="seloction mb20 ">
              <el-date-picker
                class="w-100"
                v-model="form.date"
                type="date"
                :picker-options="dateRangeOptions1"
                :placeholder="$t('message.day')"
                popper-class="my-data-khan"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-divider content-position="left">{{ $t("message.time") }}</el-divider>
            <div class="datetime-start-time mb20 time_picer">
              <el-time-picker
                  v-model="form.work_time"
                  is-range
                  range-separator="-"
                  :start-placeholder="$t('message.from')"
                  :end-placeholder="$t('message.to')"
                  value-format="HH:mm:ss"
                />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      region: "",
      value1: "",
      value: "",
      staffId:null,
      form: {},
      disable_date: true,
      disable_time: true,
      dateRangeOptions1: {
        firstDayOfWeek: 1,  
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "onlineWork/list",
      reasons: "reason/list",
    }),
  },
  mounted() {
    this.reasonsAction();
    this.staffId = getItem("userId");
  },
  methods: {
    ...mapActions({
      reasonsAction: "reason/index",
      updateList: "onlineWork/store",
    }),
    date(val) {
      if (val == "date") {
        (this.disable_date = false), (this.disable_time = false);
      }
    },
    take() {
      this.form.staff_id = getItem("userId");
      this.form.date = moment(this.form.date).format('YYYY-MM-DD');     
      this.updateList(this.form)
        .then((res) => {
          if (res.status == 201) {
            this.$alert(res);
            this.$message({
              showClose: true,
              type: 'warning',
              duration:10000,
              dangerouslyUseHTMLString: true,
              message: "<b>Sizning online ish so'rovingiz 24 soat ichida ko'rib chiqilari!</b>"
            });
            let route = this.$router.push({
              name: "onlineWork",
            });
            window.open(route.href, "_self");
          } else {
            this.$alert(res);
          }
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
<style lang="scss" >

  .time_picer{
    .el-range-input{
      color: #fff;
          background: transparent !important;
    }
    .el-range-separator{
      color: #fff;
    }
  }
  @media only screen and (max-width: 600px) {
    .my-data-khan.el-date-picker {
      width: auto !important;
      left: 0;
    }
    .time_picer>div{
      width: auto !important;
    }
  }
</style>
